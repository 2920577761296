<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="flex flex-col items-center justify-center">
    </div>
  </template>
  
  
  <script>
  
  export default {
    created() {
      this.$vs.loading();
      const isLoggedIn = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`
      );
      if(!isLoggedIn){
        const productId = this.$route.params.sharedProductId
        this.$router.push({ 
          path: '/login',
          query: { 
            sharedProductId: productId,
          } 
        });
      }
      this.$vs.loading.close();
    }
  };
  </script>
  
  <style lang="scss">
  </style>
  